import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import quotationMarkSvg from '../images/quotation_mark.svg'
import classnames from 'classnames'
import { Link } from 'gatsby'

// Note: Go to `global.scss` to manually adjust `min-height` property for the custom
// .typist-quote-min-height-fix class
// This is a temporary solution for getting the carousel of quotes to work
// with varying amounts of text

// TODO: This component might need tweaks for making it more screen reader friendly
// (eg. adding a live region that changes text when user goes through each quote). See WCAG for more details.

// This array is used to generate quotes and populate the round indicators below the quotes
export const quotes = [
  {
    title: `Mariam Raza, Program Coordinator`,
    subtitle: `Conestoga College`,
    faculty: true,
    quoteContent: `Since switching to Typist we've seen a vast increase in student engagement and a vast increase in student success. Our instructors are excited to use Typist, too, and that enthusiasm spills into the classroom. Within 3-4 weeks, students see that it’s working, and in week 9 or 10, when I ask: “Who feels that they have improved?” – every hand goes up. My students love using Typist, and in doing so gain valuable life skills, which they can apply to their work-life every single day.`,
  },
  {
    title: `Melanie Oliver, Faculty`,
    subtitle: `MacEwan University`,
    faculty: true,
    quoteContent: `For more than ten years, I have been teaching in a program that requires keyboarding and document formatting; after using Typist for only one year, I can tell you that I will never go back! I love that this program supports both Canadian and American standards for documents and spellings. I also appreciate that accuracy and technique are built into the lessons and have seen more students significantly improve their keyboarding speeds this year than ever before. The document simulations authentically mimic a “real” work environment which means that my students get the hands-on training that will serve them well in their careers. The automatic grading of documents and simple gradebook make my job evaluating document formatting and keyboarding easier than ever before. I have found the team at Typist to be very responsive and completely committed to the success of every student and instructor that signs on. I can confidently recommend Typist as my first choice in keyboarding and document production.`,
  },
  {
    title: `Sue Morrison, Faculty`,
    subtitle: `Sheridan College`,
    faculty: true,
    quoteContent: `Using Typist Keyboarding and Typist Documents has been a wonderful and much-needed upgrade to our Office Administration Program at Sheridan. It has been incredibly easy to learn and use—for both students and faculty—and the ongoing support is top notch. Students are really enjoying using the website which motivates them to practice their keyboarding regularly and as a result their improvement is remarkable. The simulated environment created for Typist Documents is brilliant. It forces students to think through the process of creating business documents in the same way they will in their Office Administration jobs. The results are exceeding our expectations! A huge thank you and congratulations to Matt and his amazing team at Typist.`,
  },
  {
    title: `Sunni Mittelstadt, Faculty`,
    subtitle: `Red Deer College`,
    faculty: true,
    quoteContent: `I’m loving Typist Documents and Typist Keyboarding! My students are being challenged in a learner-centred way to critically think while creating business documents through a realistic simulated learning experience, and the improvement is substantial. After switching to Typist Keyboarding, our students’ practice habits have improved drastically and their keyboarding times have exploded. Having the support of Matt and the Typist Team has made my job a lot easier!`,
  },
  {
    title: `Kathy Locke, Faculty`,
    subtitle: `Spartanburg Community College`,
    faculty: true,
    quoteContent: `Using Typist has turned my program around to be a very successful program for students. Students who used to fail are now passing their class. One student had taken the keyboarding class four times and could not obtain the 30 words per minute speed, but with the Typist she has achieved over 40 words per minute with 98% accuracy. The online feature has really saved this program because students have access to the program 24/7. Thank you, Typist.`,
  },
  // This is the longest quote so far
  {
    title: `DreLynn, Graudate`,
    // DreyLynn  T.
    subtitle: `Medical Office Administration, Centennial College`,
    faculty: false,
    quoteContent: `Typist is an exceptional program. I started using Typist in my college courses and continue to use Typist in my job as a Medical Office Administrator. When I first started I could type approximately 35 words per minute. With the help of Typist, I no longer need to look at the keyboard while typing and I can type over 65 words per minute at 98% accuracy. Typist brings a sense of accomplishment when you complete lessons and earn stars, and it shows clear progression while reinforcing proper typing habits. The other thing I LOVE about Typist is that it allows you to practice different terminology, including business, medical and legal. I believe this is the best type of typing tool out there, and it's the program I have been looking and waiting for these last 10 years!`,
  },
  {
    title: `Carlos, Student`,
    // Carlos C
    subtitle: `Office Administration–Legal, Conestoga College`,
    faculty: false,
    quoteContent: `I have been typing for years, even took typing course in high school. However, Typist has, in under a year, done what a lifetime of typing and my high school course could not. After taking advantage of both the lessons and timing tests, the specialization lessons being a personal favourite, I have watched my typing speed double. With the help of the Profile feature, I have even managed to get my accuracy to well over 98%.`,
  },
  {
    title: `Jason O'Shea, Faculty`,
    subtitle: `MacEwan University`,
    faculty: true,
    quoteContent: `I've used Typist in our Business Administrator training program at MacEwan University to help my students improve their typing skills and learn to efficiently format letters, memos, e-mails and reports. The greatest strength of Typist's document formatting exercises is in its consistency. Students are required to perfectly input and format several documents in increasing increments of complexity. This is intimidating at first, but absolutely necessary for training students to be conscious and vigilant in their document creation. As the assignments get more complex, Typist incorporates proofreading/editing marks for the students to interpret and teaches them to begin the process of editing and proofreading documents. I would absolutely recommend replacing your current software with Typist and wouldn't hesitate to use the site for typing, document production and formatting.`,
  },
  {
    title: `Peggy Daniel, Program Coordinator`,
    subtitle: `Mohawk College`,
    faculty: true,
    quoteContent: `One of my favourite things about Typist is how supportive their team is, with great customer service for both students and faculty. In Typist Keyboarding there are a wide variety of typing drills to help students overcome whatever their typing challenges are along with excellent diagnostic tools that help students target where they need to improve. Students aren’t just typing drills for the sake of typing drills. Typist's lesson system ensures that students have to think about their typing speed and accuracy rate from the very first typing drill lesson they do. Students who embrace Typist become polished typists.`,
  },
  {
    title: `Karyn, Student`,
    // Karyn A.
    subtitle: `Administrative Major, Macewan University`,
    faculty: false,
    quoteContent: `In MacEwan's Office Assistant program, the Document Formatting courses aim to maximize typing speed and accuracy. Typist is an easy-to-access website that provides students with opportunities to enhance their formatting capabilities and typing skills. Through regular typing lessons that range from keyboarding basics to foreign language lessons, I was able to easily work to improve my typing speed and accuracy. Using Typist, I have completed my typing assignments with great results and worked diligently on improving my keyboarding speed and accuracy. Starting with 45 words per minute and 90% accuracy, I have built up to a typing speed of 65 words per minute in just 8 months with an accuracy of 98%, placing me over the expectations of the class and giving me a competitive edge in my job-hunting future.`,
  },
  {
    title: `Suzy, Graduate`,
    // Suzy F.
    subtitle: `Office Administration, Georgian College`,
    faculty: false,
    quoteContent: `I began using Typist during one of my college courses I had started during the pandemic, it was a good time to learn something new as I had become unemployed. To be honest I almost decided not to take the keyboarding course as I felt my skills for typing were decent enough. Once I started using Typist in the course, I could see how quickly my skills had already expanded and sharpened. My accuracy and speed had both increased and  I actually enjoyed completing the lessons. I recently started a job with the Ontario Provincial Police as a Data Entry Clerk and I feel I owe my success in securing that job to the increase in my typing skills directly related to Typist. The program is easy to follow and very user friendly.`,
  },
  {
    title: `Kristy, Student`,
    // Kristy L.
    subtitle: `MacEwan University`,
    faculty: false,
    quoteContent: `While taking the Office Assistant Program with a legal major at MacEwan University I have significantly improved my typing skills. I have worked diligently through many typing tests and continue to improve every day. I appreciated that I could continuously check my progress and could focus on practicing the keys I needed to most improve. My first typing test this year was less than 50 WPM and I am now able to type between 60 - 70 WPM with 98% - 99% accuracy.`,
  },
  {
    title: `Jennifer, Student`,
    // Jennifer H.
    subtitle: `MacEwan University`,
    faculty: false,
    quoteContent: `I've always been confident in my ability to quickly type up reports and papers (only type, not write!), but once I started using Typist I quickly realized that Google Docs had been quietly correcting many of my spelling mistakes. Typist is designed to teach proper typing techniques to ensure accuracy and efficiency, required for use in my Office Technology & Skill I and II courses. I was tasked to complete weekly typing assignments, and started by learning proper keyboard technique. I had a lot of difficulties in the beginning because I had been typing with the wrong fingers for over a decade. Slowly but surely, I was able to improve my keyboarding technique, which ended up improving my accuracy and speed! I am now more confident than ever with my typing ability, as Typist has made this possible!`,
  },
].map(el => {
  const minQuoteLength = 340
  // find the first instance of a space after a particular char number
  // we'll use this space index so that our quotes don't have words broken up
  let nextSpaceIndex =
    minQuoteLength + el.quoteContent.substring(minQuoteLength).indexOf(' ')
  // check if the last char is ending a sentence, if yes, start one char earlier
  nextSpaceIndex =
    ['.', ',', '!', '?', ')'].indexOf(el.quoteContent[nextSpaceIndex - 1]) > -1
      ? nextSpaceIndex - 1
      : nextSpaceIndex
  const quoteContentShort = el.quoteContent.substring(0, nextSpaceIndex)

  return { ...el, quoteContentShort: `${quoteContentShort}...` }
})

// Directly loading image because it is an SVG (Gatsby will most likely convert it to an inline base64 image)
export const QuotationMark = ({ closing }) => (
  // Hiding this from screen readers because it is purely presentational
  // The quote itself using a <blockquote> html element should be semantic enough
  <div aria-hidden="true" className="me-typist24">
    <img
      src={quotationMarkSvg}
      className="d-none d-sm-inline"
      alt="Quotation Mark"
      style={{ width: '3rem', transform: closing ? 'rotate(180deg)' : null }}
    />
    <img
      src={quotationMarkSvg}
      alt="Quotation Mark"
      className="d-sm-none"
      style={{ width: '1.7rem', transform: closing ? 'rotate(180deg)' : null }}
    />
  </div>
)
QuotationMark.propTypes = {
  closing: PropTypes.bool,
}
QuotationMark.defaultProps = {
  closing: false,
}

// Note: the default bootstrap Carousel controls are positioned absolutely over the image (we want it on either side of the quote instead)
// so the quick solution is to add a few quick overrides using Bootstrap modifiers (eg. `position-relative`)
// The reasoning for this is also the default controls have a lot of built-in functionality (eg. hover, button behaviour, etc)
// so unless necessary, would rather not rewrite it

// This is an instance of a single quote (body text and author)
export const Quote = props => {
  const ReadMoreLink = () => (
    <span>
      <br />
      <Link
        to={`/testimonials#quote-${props.id}`}
        className="text-decoration-none"
      >
        Read Full Testimonial
      </Link>
    </span>
  )
  return (
    <blockquote id={`quote-${props.id}`}>
      <p className="lh-typist32">
        {props.quoteContent}
        {props.showReadMoreLink ? <ReadMoreLink></ReadMoreLink> : null}
      </p>
      <div className="d-flex  mt-typist24  mt-md-typist32  mt-xl-typist64">
        <div>
          <div className="fw-bold">{props.title}</div>
          <div>{props.subtitle}</div>
        </div>
      </div>
    </blockquote>
  )
}

Quote.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  quoteContent: PropTypes.string.isRequired,
  // This is useful for showing a Read More link in carousel view, but not on the
  // testimonials page
  showReadMoreLink: PropTypes.bool.isRequired,
}

const Indicators = props => {
  return (
    <div className="carousel-indicators typist-carousel-indicators position-relative mb-typist40 mb-md-0 flex-wrap justify-content-start justify-content-md-center">
      {props.quotes.map((quote, idx) => (
        <button
          key={`indicator-${idx}`}
          type="button"
          data-bs-target="#quotesCarouselIndicators"
          data-bs-slide-to={`${idx}`}
          className={classnames({ active: idx === 0 })}
          aria-current={idx === 0}
          aria-label={`Quote ${idx + 1}`}
        ></button>
      ))}
    </div>
  )
}

Indicators.propTypes = {
  quotes: PropTypes.array,
}

const QuotesCarousel = () => {
  return (
    <div
      id="quotesCarouselIndicators"
      className="carousel slide"
      data-bs-ride="carousel"
      data-bs-interval="7000"
      style={{ maxWidth: '60rem' }}
    >
      <div className="carousel-inner">
        {quotes.map((quote, idx) => (
          <div
            key={`carousel-item-${idx}`}
            className={classnames('carousel-item', { active: idx === 0 })}
          >
            <div className="typist-quote-min-height-fix mb-typist40 mb-md-typist32 mb-xl-typist64">
              <div className="d-block d-md-none">
                <Quote
                  title={quote.title}
                  id={idx + 1}
                  subtitle={quote.subtitle}
                  quoteContent={quote.quoteContentShort}
                  showReadMoreLink={true}
                ></Quote>
              </div>
              <div className="d-none d-md-block">
                {/* Render short form of the quote in mobile  */}
                <Quote
                  title={quote.title}
                  id={idx + 1}
                  subtitle={quote.subtitle}
                  quoteContent={quote.quoteContent}
                  showReadMoreLink={false}
                ></Quote>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Indicators quotes={quotes}></Indicators>
    </div>
  )
}

const LeftChevron = () => (
  <button
    className="d-none d-xl-block carousel-control-next position-relative flex-grow-1"
    type="button"
    data-bs-target="#quotesCarouselIndicators"
    data-bs-slide="prev"
  >
    <span
      className="carousel-control-prev-icon"
      style={{ filter: 'invert(1) grayscale(100)' }}
      aria-hidden="true"
    ></span>
    <span className="visually-hidden">Previous</span>
  </button>
)

const RightChevron = () => (
  <button
    className="d-none d-xl-block carousel-control-next position-relative flex-grow-1"
    type="button"
    data-bs-target="#quotesCarouselIndicators"
    data-bs-slide="next"
  >
    <span
      className="carousel-control-next-icon"
      style={{ filter: 'invert(1) grayscale(100)' }}
      aria-hidden="true"
    ></span>
    <span className="visually-hidden">Next</span>
  </button>
)

const TestimonialsCarousel = () => {
  return (
    <section className="mb-typist56 mb-md-typist96 mb-xl-typist240">
      <div className="container d-flex justify-content-end">
        <StaticImage
          src="../images/shrub_big@2x.png"
          width={203}
          alt=""
          placeholder="none"
        ></StaticImage>
      </div>

      <div className="border-bottom border-top border-dark">
        <div className="container">
          <section className="d-flex pt-typist40 pt-md-typist128 flex-column">
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex justify-content-between w-100">
                <LeftChevron></LeftChevron>
                <div className="d-flex">
                  <QuotationMark></QuotationMark>
                  <QuotesCarousel></QuotesCarousel>
                </div>
                <RightChevron></RightChevron>
              </div>
            </div>
          </section>
        </div>

        <div className="container d-flex justify-content-start">
          <StaticImage
            src="../images/shrub_small@2x.png"
            width={104}
            alt=""
            placeholder="none"
          ></StaticImage>
        </div>
      </div>
    </section>
  )
}

export default TestimonialsCarousel
